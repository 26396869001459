import { nextTick } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'

import Status from '@/pages/Status'

const DEFAULT_TITLE = 'RED HOUSE';

const routes = [
  {
    name: 'Status',
    path: '/',
    component: Status,
    meta: {
      title: `상태 - ${DEFAULT_TITLE}`,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export { router }
