<template>
  <NumberController unit="%"
    :value="humidity" />
</template>

<script>
import NumberController from '@/components/NumberController.vue';

export default {
  name: 'HumidityController',
  components: {
    NumberController,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    humidity() {
      return this.value * 100
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "primeicons/primeicons.css";

.temperature-controller {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  background-color: black;
  border: 1px solid white;
  width: 200px;
  height: 80px;

  & > .current-temperature {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

    flex-grow: 1;
    padding: 10px;

    font-size: 1.5rem;
    color: white;
    & > .unit {
      margin-left: 5px;
    }
  }

  & > .buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 50px;


    & > button {
      flex-grow: 1;

      border: none;
      background-color: gray;
      &:active {
        background-color: darkgray;
      }
    }
  }
}

</style>
