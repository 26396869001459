<template>
  <div class="status-page">
    <TemperatureController :value="targetTemperature" />
    <HumidityController :value="targetHumidity" />
  </div>
</template>

<script>
import TemperatureController from '@/components/TemperatureController.vue';
import HumidityController from '@/components/HumidityController.vue';

export default {
  name: 'Status',
  components: {
    TemperatureController,
    HumidityController,
  },
  data() {
    return {
      targetTemperature: 24.0,
      targetHumidity: 0.6,
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.status-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: black;
}
</style>
