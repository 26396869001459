<template>
  <div class="temperature-controller">
    <div class="current-temperature">
      <span>{{ displayValue }}</span>
      <span class="unit">{{ unit }}</span>
    </div>
    <div class="buttons">
      <button>
        <i class="pi pi-angle-up"></i>
      </button>
      <button>
        <i class="pi pi-angle-down"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumberController',
  props: {
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
    points: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    displayValue() {
      return this.value.toFixed(this.points);
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "primeicons/primeicons.css";

.temperature-controller {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  background-color: black;
  border: 1px solid white;
  width: 200px;
  height: 80px;

  & > .current-temperature {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

    flex-grow: 1;
    padding: 10px;

    font-size: 1.5rem;
    color: white;
    & > .unit {
      margin-left: 5px;
    }
  }

  & > .buttons {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 50px;


    & > button {
      flex-grow: 1;

      border: none;
      background-color: gray;
      &:active {
        background-color: darkgray;
      }
    }
  }
}

</style>
