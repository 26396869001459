<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  async created() {
    await this.$store.dispatch('loadUserInfo')
  },
}

</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  margin: 0;
  padding: 0;
  min-width:  1080px;
}

#app {
  width: 800px;
  height: 480px;

  background-color: black;
}

</style>
